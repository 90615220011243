<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Servicios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga Seca</li>
                  <li class="breadcrumb-item active">Servicios</li>
                  <li class="breadcrumb-item active">Nacionales</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-md-1">
                    <label>N° </label>
                    <input
                      type="number"
                      v-model="servicioNacional.id"
                      placeholder="N°"
                      label="id"
                      class="form-control form-control-sm"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Tipo servicio </label>
                    <select
                      id="tipo_servicio"
                      class="form-control form-control-sm"
                      v-model="servicioNacional.tipo_servicio"
                      disabled
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_servicio in listasForms.tipos_servicios"
                        :key="tipo_servicio.numeracion"
                        :value="tipo_servicio.numeracion"
                      >
                        {{ tipo_servicio.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div
                    class="form-group col-md-3"
                    v-if="
                      servicioNacional.equipo != null &&
                      servicioNacional.equipo != undefined
                    "
                  >
                    <label>Placa </label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="servicioNacional.equipo.placa"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Conductor/Operador </label>
                    <select
                      id="persona_type"
                      v-model="servicioNacional.persona_type"
                      class="form-control form-control-sm p-0"
                      disabled
                    >
                      <option value="">Seleccione...</option>
                      <option value="App\Conductor">Conductor</option>
                      <option value="App\Operador">Operador</option>
                    </select>
                  </div>
                  <div
                    class="form-group col-md-4"
                    v-if="
                      servicioNacional.persona != null &&
                      servicioNacional.persona != undefined
                    "
                  >
                    <label>Nombre </label>
                    <input
                      type="text"
                      id="nombre"
                      class="form-control form-control-sm"
                      v-model="servicioNacional.persona.nombre_completo"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>Fecha Inicial </label>
                    <div class="row">
                      <div class="col-md-6">
                        <input
                          type="date"
                          id="fecha_ini"
                          class="form-control form-control-sm"
                          v-model="servicioNacional.fecha_ini1"
                          :class="
                            $v.servicioNacional.fecha_ini1.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          :disabled="servicioNacional.fecha_fin"
                          @input="validaFecha"
                        />
                      </div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          id="hora_ini1"
                          class="form-control form-control-sm"
                          v-model="servicioNacional.hora_ini1"
                          :class="
                            $v.servicioNacional.hora_ini1.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          v-mask="{
                            mask: '99:99',
                            hourFormat: '23',
                          }"
                          :disabled="servicioNacional.fecha_fin"
                          @input="validaFecha"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Fecha Final </label>
                    <div class="row">
                      <div class="col-md-6">
                        <input
                          type="date"
                          id="fecha_fin"
                          class="form-control form-control-sm"
                          v-model="servicioNacional.fecha_fin1"
                          :class="
                            $v.servicioNacional.fecha_fin1.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          :disabled="
                            $refs.CsServicioDetalle &&
                            $refs.CsServicioDetalle.csDetalles.length > 0
                          "
                          @input="validaFecha"
                        />
                      </div>
                      <div class="col-md-6">
                        <input
                          type="text"
                          id="hora_fin"
                          class="form-control form-control-sm"
                          v-model="servicioNacional.hora_fin1"
                          :class="
                            $v.servicioNacional.hora_fin1.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          v-mask="{
                            mask: '99:99',
                            hourFormat: '23',
                          }"
                          :disabled="
                            $refs.CsServicioDetalle &&
                            $refs.CsServicioDetalle.csDetalles.length > 0
                          "
                          @input="validaFecha"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Estado </label>
                    <select
                      id="estado"
                      class="form-control form-control-sm"
                      v-model="servicioNacional.estado"
                      @change="getIndex()"
                      disabled
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                        disabled
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-9">
                    <label for="trabajo_realizado">Trabajo realizado</label>
                    <textarea
                      v-model="servicioNacional.trabajo_realizado"
                      cols="30"
                      rows="5"
                      class="form-control form-control-sm"
                      :class="
                        $v.servicioNacional.trabajo_realizado.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CsServicioDetalle ref="CsServicioDetalle" v-if="detalle === 1" />
          <CsServicioPresupuesto
            ref="CsServicioPresupuesto"
            v-if="presupuesto === 1"
          />
          <CsServicioDetalleFirmas
            ref="CsServicioDetalleFirmas"
            v-if="servicioNacional.firmas && servicioNacional.firmas.length > 0"
          />
          <div class="card-footer">
            <div class="row">
              <button class="btn bg-secondary col-md-1" @click="back()">
                <i class="fas fa-reply"></i><br />Volver
              </button>

              <button
                class="btn bg-primary col-md-1"
                v-if="
                  $refs.CsServicioDetalle !== null &&
                  $refs.CsServicioDetalle != undefined
                "
                v-show="
                  !$v.servicioNacional.$invalid &&
                  $refs.CsServicioDetalle.fin_reportes &&
                  $refs.CsServicioPresupuesto.totalPresupuesto == 100
                "
                @click="saveTotal()"
              >
                <i class="fas fa-paper-plane"></i><br />Guardar
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, helpers } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import moment from "moment";
import CsServicioDetalle from "./CsServicioDetalle";
import CsServicioPresupuesto from "./CsServicioPresupuesto";
import CsServicioDetalleFirmas from "./CsServicioDetalleFirmas";

const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);

export default {
  name: "CsServicioForm",
  components: {
    Loading,
    CsServicioDetalle,
    CsServicioPresupuesto,
    CsServicioDetalleFirmas,
  },
  data() {
    return {
      cargando: false,
      sitio: null,
      bloque: null,
      servicioNacional: {
        id: null,
        tipo_servicio: null,
        placa: null,
        persona_type: null,
        bloque_id: null,
        sitio_id: null,
        fecha_ini: null,
        fecha_ini1: null,
        hora_ini1: null,
        fecha_fin: null,
        fecha_fin1: null,
        hora_fin1: null,
        trabajo_realizado: null,
        estado: null,
      },
      listasForms: {
        tipos_servicios: [],
        tipo_presupuesto: [],
        tipo_tiempos: [],
        estados: [],
      },
      detalle: 0,
      presupuesto: 0,
    };
  },

  validations: {
    servicioNacional: {
      fecha_ini1: {
        required,
      },
      hora_ini1: {
        required,
        timeFormat,
      },

      fecha_fin1: {
        required,
      },

      hora_fin1: {
        required,
        timeFormat,
      },
      trabajo_realizado: {
        required,
      },
    },
  },
  methods: {
    async getIndex() {
      let me = this;
      me.cargando = true;
      this.servicioNacional = {
        id: null,
        tipo_servicio: null,
        placa: null,
        persona_type: null,
        bloque_id: null,
        sitio_id: null,
        fecha_ini: null,
        fecha_ini1: null,
        hora_ini1: null,
        fecha_fin: null,
        fecha_fin1: null,
        hora_fin1: null,
        trabajo_realizado: null,
        estado: null,
      };

      me.$route.params.servicio.fecha_ini = moment(
        me.$route.params.servicio.fecha_ini
      ).format("YYYY-MM-DD HH:mm");
      const inicio = moment(me.$route.params.servicio.fecha_ini);
      let fecha_ini1 = await moment(inicio).format("YYYY-MM-DD");
      let hora_ini1 = await moment(inicio).format("HH:mm");

      // Se filtra el contenido de la fecha hora de fin
      let fecha_fin1 = null;
      let hora_fin1 = null;
      if (me.$route.params.servicio.fecha_fin) {
        me.$route.params.servicio.fecha_fin = moment(
          me.$route.params.servicio.fecha_fin
        ).format("YYYY-MM-DD HH:mm");
        const fin = moment(me.$route.params.servicio.fecha_fin);
        fecha_fin1 = await moment(fin).format("YYYY-MM-DD");
        hora_fin1 = await moment(fin).format("HH:mm");
      }

      me.servicioNacional = {
        ...me.$route.params.servicio,
        fecha_ini1,
        hora_ini1,
        fecha_fin1,
        hora_fin1,
      };

      me.bloque = { ...me.$route.params.servicio.bloque };
      me.sitio = { ...me.$route.params.servicio.sitio };
      me.servicioNacional.persona.nombre_completo =
        me.$route.params.servicio.persona.nombres +
        " " +
        me.$route.params.servicio.persona.apellidos;
      me.detalle = me.$route.params.detalle;
      me.presupuesto = me.$route.params.presupuesto;
      me.cargando = false;
    },

    async getTipoServicio() {
      await axios.get("/api/lista/128").then((response) => {
        this.listasForms.tipos_servicios = response.data;
      });
    },

    async getEstados() {
      await axios.get("/api/lista/143").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTipoPresupuesto() {
      this.listasForms.tipo_presupuesto = [];
      axios.get("/api/lista/151").then((response) => {
        this.listasForms.tipo_presupuesto = response.data;
      });
    },

    async save() {
      this.servicioNacional.fecha_ini = `${this.servicioNacional.fecha_ini1} ${this.servicioNacional.hora_ini1}`;
      this.servicioNacional.fecha_fin = `${this.servicioNacional.fecha_fin1} ${this.servicioNacional.hora_fin1}`;
      if (!this.$v.servicioNacional.$invalid) {
        this.cargando = true;
        axios({
          method: "PUT",
          url: "/api/cs/servicios",
          data: this.servicioNacional,
        })
          .then((response) => {})
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title:
                "Ha ocurrido un error, por favor intente la acción nuevamente.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    async saveTotal() {
      this.cargando = true;
      try {
        await this.save();
        await this.$refs.CsServicioDetalle.saveDetalleTotal();
        await this.$refs.CsServicioPresupuesto.saveContableTotal();
        await this.$swal({
          icon: "success",
          title: "Se guardó exitosamente...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } catch (error) {
        await this.$swal({
          icon: "error",
          title:
            "Ha ocurrido un error, por favor intente la acción nuevamente.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.cargando = false;
    },

    validaFecha() {
      if (
        this.servicioNacional.fecha_ini1 &&
        this.servicioNacional.hora_ini1 &&
        this.servicioNacional.hora_fin1 &&
        this.servicioNacional.fecha_fin1
      ) {
        const fecha_menor = new Date(
          `${this.servicioNacional.fecha_ini1} ${this.servicioNacional.hora_ini1}`
        );
        const fecha_mayor = new Date(
          `${this.servicioNacional.fecha_fin1} ${this.servicioNacional.hora_fin1}`
        );

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.servicioNacional.fecha_fin = "";
          this.servicioNacional.fecha_fin1 = "";
          this.servicioNacional.hora_fin1 = "";
          this.$swal({
            icon: "error",
            title: `La Fecha Inicio no puede ser mayor a la Fecha Fin..`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }

        // Se valida que la diferencias de fechas no sea mayor a 24 horas
        const diffFechas = fecha_mayor.getTime() - fecha_menor.getTime();
        const diffHoras = diffFechas / (1000 * 60 * 60);
        if (24 <= diffHoras) {
          this.servicioNacional.fecha_fin = "";
          this.servicioNacional.fecha_fin1 = "";
          this.servicioNacional.hora_fin1 = "";
          this.$swal({
            icon: "error",
            title: `La diferecnia entre la Fecha Inicio y Fecha Fin no puede ser mayor a 24 horas..`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    back() {
      return this.$router.replace("/Cs/ServiciosNacionales");
    },
  },

  mounted() {
    this.getIndex();
    this.getTipoServicio();
    this.getTipoPresupuesto();
    this.getEstados();
  },
};
</script>
